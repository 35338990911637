import Anchor from 'Shared/Anchor/Anchor'
import StepIndicator from './StepIndicator'

const Step = ({ navigateToRoute, hideStepNumber, isActive, isClickable, isLastStep, size, step }) => {
  const classLabel = { numberStep: 'fw-bold label', nameStep: 'label name' }

  if (!isActive || !isClickable || isLastStep)
    return (
      <>
        <StepIndicator isLast={isLastStep} size={size} step={step} />
        {!hideStepNumber && <p className={classLabel.numberStep}>Step {step.index}</p>}
        <p className={classLabel.nameStep}>{step.name}</p>
      </>
    )

  return (
    <>
      <StepIndicator {...{ isActive, navigateToRoute, size, step }} />

      {!hideStepNumber && (
        <Anchor className={classLabel.numberStep} href={`#${step.name}`} onClick={navigateToRoute}>
          Step {step.index}
        </Anchor>
      )}

      <Anchor className={classLabel.nameStep} href={`#${step.name}`} onClick={navigateToRoute}>
        {step.name}
      </Anchor>
    </>
  )
}

export default Step
