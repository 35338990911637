import { useSelector } from 'react-redux'
import cnames from 'classnames'
import { AlertShowError } from 'Shared/Alert'
import './Skeleton.scss'

export const withSkeleton = (WrappedComponent, reduxCallback, skeletonData) => {
  const WithoutRedux = ({ error, loading, ...props }) => {
    const className = cnames({ 'skeleton-loading': loading })

    if (error) return <AlertShowError className="mt-5" error={error} />

    return (
      <div className={className}>
        <WrappedComponent {...props} />
      </div>
    )
  }

  const WithRedux = ({ ...props }) => {
    const { error, loading } = useSelector(reduxCallback)
    const className = cnames({ 'skeleton-loading': loading })

    if (error) return <AlertShowError className="mt-5" error={error} />

    const skeletonFakeData = loading ? skeletonData : null

    return (
      <div className={className}>
        <WrappedComponent skeletonFakeData={skeletonFakeData} {...props} />
      </div>
    )
  }

  return reduxCallback ? WithRedux : WithoutRedux
}
