import { useStripe } from '@stripe/react-stripe-js'

export const useStripeConfirm = () => {
  const stripe = useStripe()

  const reviewStatus = ({ error, onSuccess, paymentIntent, setError }) => {
    if (error) return setError(error.message)

    const { next_action: nextAction, status } = paymentIntent
    if (status === 'requires_payment_method')
      setError('Please attempt again with a different payment method.')
    else if (nextAction?.type === 'verify_with_microdeposits')
      setError('The account needs to be verified via microdeposits.')
    else if (['processing', 'succeeded'].includes(status))
      onSuccess(true)
  }

  const callConfirm = ({ clientSecret, onSuccess, paymentMethodType, setError }) => {
    if (paymentMethodType === 'us_bank_account')
      stripe.confirmUsBankAccountPayment(clientSecret)
        .then(({ paymentIntent, error }) => {
          reviewStatus({ error, onSuccess, paymentIntent, setError })
        })
    else
      stripe.confirmCardPayment(clientSecret)
        .then(({ paymentIntent, error }) => {
          reviewStatus({ error, onSuccess, paymentIntent, setError })
        })
      }

  return [callConfirm]
}
