let CamperReducer

(() => {
  CamperReducer = (currentState = {}, { type, payload }) => {
    const handler = camperHandlers[type]
    if (handler) return handler(currentState, payload)

    return currentState
  }

  const camperSetData = (currentState, payload = {}) => ({
    ...currentState,
    ...payload,
  })

  const camperHandlers = {
    CAMPER_SET_DATA: camperSetData,
  }
})()

export default CamperReducer
