import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ViewModal from 'Shared/ViewModal/ViewModal'

// TODO: Refactor all forms that use this component with the app Shared/PaymentMethods components and
//       then refactor this file to a more optimal solution to remove the renderProp
const AlertModal = ({ children, onClose, show, showCloseIcon, title, useRenderProps }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(prevState => !prevState)

  useEffect(() => {
    setModal(show)
  }, [show])

  return (
    <ViewModal isOpen={modal} onClosed={onClose} showCloseIcon={showCloseIcon} showOkButton={false} size="lg"
               toggle={toggle} title={title}>
      {useRenderProps ? children({ setModal, toggle }) : children }
    </ViewModal>
  )
}

AlertModal.defaultProps = {
  onClose: null,
  title: '',
  showCloseIcon: false,
  useRenderProps: false,
}

AlertModal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  showCloseIcon: PropTypes.bool,
  useRenderProps: PropTypes.bool,
}

export default AlertModal
