import PropTypes from 'prop-types'
import storeConnect from 'Checkout/Redux/Connect'

const Alerts = ({ alerts }) =>
  <ul>
    {alerts.map(alert => <li className="pb-2" key={alert}>{alert}</li>)}
  </ul>

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const mapStateToProps = state => ({
  alerts: state.campground.alerts,
})

export default storeConnect({ mapStateToProps })(Alerts)
