import { removeItemSessionStorage, setSessionStorage } from 'Utils/SessionStorage'

const PaymentReducer = (currentState = {}, action) => {
  const handler = paymentHandlers[action.type]
  if (handler) return handler(currentState, action)

  return currentState
}

const paymentSetData = (currentState, { payload = {} }) => ({
  ...currentState,
  ...payload,
})

const updatePaymentSiteLock = (currentState, action) => {
  const { slug, payload } = action

  if (!payload.isSiteLock) removeItemSessionStorage(`${slug}-site`)

  setSessionStorage(`${slug}-isSiteLock`, payload)

  return {
    ...currentState,
    ...payload,
  }
}

const paymentHandlers = {
  PAYMENT_SET_DATA: paymentSetData,
  UPDATE_PAYMENT_SITE_LOCK: updatePaymentSiteLock,
}

export default PaymentReducer
