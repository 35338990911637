import { createMiddleware, createMetaReducer } from 'redux-beacon'
import GoogleAnalytics from '@redux-beacon/google-analytics'
import eventsMapperGA from '../Reducers/TrackGA'

const ga = GoogleAnalytics()

const gaMiddleware = createMiddleware(eventsMapperGA, ga)
const gaMetaReducer = createMetaReducer(eventsMapperGA, ga)

export { gaMiddleware, gaMetaReducer }
