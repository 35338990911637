import { Col } from 'reactstrap'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import storeConnect from 'POS/Redux/Connect'
import HelpLink from 'Shared/Admin/HelpLink'
import './WithCart.scss'

const WithCart = ({ children, Cart, cartProps, classNamesCart, helpTrainingVideosUrl, isCartSticky }) => {
  const classes = {
    withCartContainer: cnames('d-flex flex-column flex-lg-row position-relative with-cart'),
    cartContainer: cnames(classNamesCart, { 'cart-container__sticky sticky-top': isCartSticky }),
  }

  return (
    <div className={classes.withCartContainer}>
      <Col className="payments mb-3 p-0 pe-md-2" md={12} lg={7}>
        {children}
      </Col>

      {Cart && (
        <Col className="cart-container p-0 ps-md-2" lg={5}>
          <div className={classes.cartContainer}>
            {helpTrainingVideosUrl && (
              <div className="my-2 d-flex flex-row-reverse">
                <HelpLink showLabel url={helpTrainingVideosUrl} />
              </div>
            )}
            <Cart {...cartProps} />
          </div>
        </Col>
      )}
    </div>
  )
}

WithCart.defaultProps = {
  Cart: null,
  classNamesCart: 'pb-4',
  cartProps: {},
  helpTrainingVideosUrl: '',
  isCartSticky: true,
}

WithCart.propTypes = {
  Cart: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  classNamesCart: PropTypes.string,
  cartProps: PropTypes.shape({}),
  helpTrainingVideosUrl: PropTypes.string,
  isCartSticky: PropTypes.bool,
}

const mapStateToProps = state => ({
  helpTrainingVideosUrl: state.campground?.externalLinks.helpTrainingVideos,
})

export default storeConnect({ mapStateToProps })(WithCart)
