import { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import FacebookLogin from 'react-facebook-login'
import storeConnect from 'Checkout/Redux/Connect'
import SignInViewSchema from './SignInViewSchema'
import useCurrentUser from 'Checkout/Hooks/useCurrentUser'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import MessageError from 'Shared/MessageError/MessageError'
import Separator from 'Shared/Separator/Separator'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'

const SignInModal = ({ camperId, fbAppId, forgotPassword, setSignInModal, signInModal }) => {
  const [signInUser, signInFacebook, errorSignInUser] = useCurrentUser()
  const [disableErrorApi, setDisableErrorApi] = useState(true)

  useEffect(() => {
    if (camperId) hideModal()
  }, [camperId])

  const hideModal = () => setSignInModal(false)

  const handleSignIn = values => {
    window.analytics?.reset()
    setDisableErrorApi(true)

    if (!values?.accessToken) return signInUser(values.email, values.password)

    const { email, id, name, picture } = values
    const { url: urlPicture } = picture.data
    signInFacebook(email, id, name, urlPicture)
  }

  return (
    <Modal id="sign-in-modal" isOpen={signInModal} toggle={hideModal} className="modal-dialog-centered">
      <ModalHeader toggle={hideModal} className="border-0 py-1 px-3" />

      <ModalBody className="px-5 py-2">
        <div className="mb-4 text-center">
          <h5 className="fw-bold text-center mb-2">Make reservations faster than ever.</h5>
          <p className="text-center text-gray">
            Sign in with Roverpass and we'll keep all your information handy for every adventure
          </p>
        </div>

        <div className="mb-2 col-10 offset-1 text-center">
          {(errorSignInUser && disableErrorApi) && <MessageError error={errorSignInUser} /> }

          <div className="fb-wrap">
            <FacebookLogin appId={fbAppId} fields="email,name,picture" callback={handleSignIn}
                           icon={<FontAwesomeIcon className="me-2" icon={['fab', 'facebook-f']} />}
                           cssClass="w-100 btn-facebook border-0 fw-bold py-2 mb-3" />
          </div>

          <Separator className="my-3 py-2" message={<small>or</small>} />
          <FormWithValidations initialValues={{ email: '', password: '' }} validationSchema={SignInViewSchema}
                               onSubmit={handleSignIn}>
            {({ values }) => {
              useEffect(() => {
                if (disableErrorApi) setDisableErrorApi(false)
              }, [values])

              return (
                <>
                  <FloatingLabelInput className="text-start" label="Email Address" name="email" type="email" />

                  <FloatingLabelInput className="text-start" label="Password" name="password" type="password" />

                  <Button className="w-100 btn-theme-primary fw-bold my-3 py-2 px-5" type="submit">
                    Log In
                  </Button>
                </>
              )
            }}
          </FormWithValidations>
        </div>

        <div className="mb-2 mt-4">
          <small>
            <a className="text-gray" href={forgotPassword} target="_blank" rel="noreferrer">Forgot Password?</a>
          </small>
        </div>

      </ModalBody>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  setSignInModal: signInModal => dispatch(searchFilterUpdate({ signInModal })),
})

const mapStateToProps = state => ({
  camperId: state.camper.id,
  fbAppId: state.options.fbAppId,
  forgotPassword: state.options.externalLinks.forgotPassword,
  signInModal: state.search.signInModal,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(SignInModal)
