const ErrorsReducer = (currentState = {}, action) => {
  const handler = errorHandlers[action.type]
  if (handler) return handler(currentState, action)

  return currentState
}

const setGlobalError = (currentState, { payload }) => ({
  globalError: payload,
})

const errorHandlers = {
  SET_GLOBAL_ERROR: setGlobalError,
}

export default ErrorsReducer
