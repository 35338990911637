import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const ArrowButton = ({ className, icon, onClick }) => {
  const classname = cnames('arrow arrow-previous border-0 bg-white shadow', className)

  return (
    <button className={classname} onClick={onClick} type="button">
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

export default ArrowButton
