import store from 'Checkout/Redux/Store'

const searchFilterUpdate = data => ({
  type: 'SEARCH_FILTER_UPDATE', payload: data,
})

const searchUpdateWithSessionStorage = (data, sessionKey) => {
  const campgroundSlug = store.getState().campground.slug

  return {
    type: 'SEARCH_UPDATE_WITH_SESSION_STORAGE',
    payload: data,
    sessionKey: `${campgroundSlug}-${sessionKey}`,
  }
}

export { searchFilterUpdate, searchUpdateWithSessionStorage }
