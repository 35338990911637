import { useState } from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SingleDatePicker } from 'react-dates'
import { dateBetween, parseMoment, today } from 'Utils/Dates'
import 'react-dates/lib/css/_datepicker.css'
import '../DatePicker.scss'
import './DatePickerSingle.scss'

const DatePickerSingle = ({ block, className, colorIcon, closureDates, date, direction, disabled, displayFormat,
                            icon, id, initialMonth, isOutsideRange, label, labelClassName, onDateChange,
                            placeholder, showDefaultInputIcon, small, textToLeft }) => {
  const [focused, setFocused] = useState(false)
  const currentDate = parseMoment(date)
  // NOTE: Don't move this var to default props because moment won't take the correct Date (Test only)
  const initialMonthProp = initialMonth ?? today()

  const classes = cnames('wrap-SingleDatePicker', className,
                         { 'direction-right': direction === 'right', 'text-align-left': textToLeft })
  const customInputIcon = icon && <FontAwesomeIcon color={colorIcon} icon={icon} />

  const isDayBlocked = day => {
    if (!closureDates || !closureDates.length) return false

    return closureDates.some(range => dateBetween(day, { from: range.from, to: range.to }))
  }

  return (
    <div className={classes}>
      {label && (
        <Label className={labelClassName} htmlFor={id}>
          {label}
        </Label>
      )}

      <SingleDatePicker anchorDirection={direction} block={block} customInputIcon={customInputIcon}
                        date={currentDate} disabled={disabled} displayFormat={displayFormat}
                        focused={focused} id={id} initialVisibleMonth={() => currentDate || initialMonthProp}
                        isDayBlocked={isDayBlocked} isOutsideRange={isOutsideRange} numberOfMonths={1}
                        onDateChange={onDateChange} onFocusChange={({ focused }) => setFocused(focused)}
                        placeholder={placeholder} readOnly showDefaultInputIcon={showDefaultInputIcon}
                        small={small} />
    </div>
  )
}

DatePickerSingle.defaultProps = {
  block: false,
  closureDates: [],
  colorIcon: '#dbdbdb',
  date: null,
  direction: 'left',
  disabled: false,
  displayFormat: 'MM/DD/YYYY',
  icon: null,
  id: 'date-picker-single',
  initialMonth: null,
  isOutsideRange: () => {},
  label: '',
  labelClassName: null,
  placeholder: 'MM/DD/YYYY',
  showDefaultInputIcon: false,
  small: false,
  textToLeft: false,
}

DatePickerSingle.propTypes = {
  block: PropTypes.bool,
  closureDates: PropTypes.arrayOf(PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  })),
  colorIcon: PropTypes.string,
  date: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  displayFormat: PropTypes.string,
  icon: PropTypes.shape({}),
  id: PropTypes.string,
  initialMonth: PropTypes.shape({}),
  isOutsideRange: PropTypes.func,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showDefaultInputIcon: PropTypes.bool,
  small: PropTypes.bool,
  textToLeft: PropTypes.bool,
}

export default DatePickerSingle
