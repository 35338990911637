import { useState } from 'react'
import cnames from 'classnames'
import { isTestEnv } from 'Utils/enviroment'
import { Label } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'
import FormTextArea from 'Shared/Formik/FormTextInput'
import RichTextWithFormik from 'Shared/Formik/RichTextWithFormik'
import TinyMce from 'Shared/TinyMce/TinyMce'

const FormTinyMce = ({ className, isRequired, label, labelClasses, name, placeholder, showErrors }) => {
  const [isPreview, setIsPreview] = useState(true)

  const togglePreview = () => setIsPreview(prev => !prev)

  if (isPreview) return <RichTextWithFormik {...{ label, labelClasses, name }} onClickLabel={togglePreview} />
  if (isTestEnv) return <FormTextArea {...{ className, label, labelClasses, name, placeholder }} rows={4}
                                      type="textarea" />

  const classes = {
    container: cnames('form-tiny-mce', className),
    label: cnames('main-label', labelClasses),
  }

  return (
    <FormGroupField className={classes.container} name={name} showErrors={showErrors}>
      {({ field, form: { setFieldValue } }) => {
        const handleChange = value => {
          if (value !== `<p>${placeholder}</p>` || !placeholder)
            setFieldValue(name, value)
        }

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}

                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}

            <TinyMce id={`tiny-textarea-${name}`} onChange={handleChange} value={field.value || placeholder || ''} />
          </>
        )
      }}
    </FormGroupField>
  )
}

export default FormTinyMce
