import cnames from 'classnames'
import PropTypes from 'prop-types'
import HeaderCell from 'Shared/TableList/HeaderCell'
import Row from 'Shared/TableList/Row'

const BASE_STYLES = 'align-items-center d-flex justify-content-between not-loading py-3 px-2 row'

const TableList = ({ CustomRow, data, headers }) => (
  <div className="table-list">
    <div className={cnames('table-list--header', BASE_STYLES)}>
      {headers.map(header => <HeaderCell key={header.name} header={header} />)}
    </div>

    <div className="table-list--body">
      {data.map(item => (CustomRow ? (
        <CustomRow headers={headers} item={item} key={item.id} />
      ) : (
        <Row headers={headers} item={item} key={item.id} />)
      ))}
    </div>
  </div>
)

TableList.defaultProps = {
  data: [],
}

TableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default TableList
