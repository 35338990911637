import { useEffect, useState } from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './Campsite.scss'

const Campsite = ({ added, campsite, isFlexible, onSelect, rate }) => {
  const [{ availableDateText, isSameDay }, setNextGuestData] = useState({ availableDateText: 'Open',
                                                                          isSameDay: false })
  const { amenitiesSummary, eventNextBooking, name, reservableByGuest } = campsite

  const classes = {
    availableDate: cnames({ 'text-salmon': isSameDay }),
    container: cnames('campsite__site rate', { active: added, 'reservable-by-guest': !reservableByGuest }),
    data: cnames('campsite__data', { 'me-4': isFlexible }),
  }
  const showName = reservableByGuest ? name : `${name} - Unavailable Online`

  const selectCampsite = () => (onSelect && onSelect(campsite))

  const validNextGuestLabel = () => {
    if (eventNextBooking) {
      const { datesLabel, isSameDay } = eventNextBooking

      setNextGuestData({ availableDateText: datesLabel, isSameDay })
    }
  }

  useEffect(() => {
    validNextGuestLabel()
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.data}>
        <h6 className="campsite__id mb-1">{showName}</h6>

        <h6 className="opacity-75">{amenitiesSummary}</h6>

        {!isFlexible && (
          <span className="h6 opacity-75">{rate.nightPrice?.displayWithCode}</span>
        )}
      </div>

      {!isFlexible && (
        <div className="campsite__data ps-2 mt-auto fw-bold">
          <span className="me-1">Next Guest:</span>
          <span className={classes.availableDate}>{availableDateText}</span>
        </div>
      )}

      <div className="campsite__total">
        {(isFlexible && rate?.totalPrice?.amount > 0) && (
          <>
            <span className="h5 mb-0">{rate.totalPrice.display}</span>
            <span className="text-muted">Per Month</span>
          </>
        )}

        {!isFlexible && (
          <>
            <span className="text-facebook">{rate.totalNights} nights</span>
            <span className="h5">{rate?.totalPrice?.display}</span>
          </>
        )}
      </div>

      <Button color="action" disabled={added} onClick={selectCampsite}>
        {added && <FontAwesomeIcon icon="check" />}
        {added ? 'Added' : 'Add'}
      </Button>
    </div>
  )
}

Campsite.defaultProps = {
  added: false,
  onSelect: () => {},
}

Campsite.propTypes = {
  added: PropTypes.bool,
  campsite: PropTypes.shape({
    amenitiesSummary: PropTypes.string.isRequired,
    eventNextBooking: PropTypes.shape({
      datesLabel: PropTypes.string,
      isSameDay: PropTypes.bool,
    }),
    name: PropTypes.string.isRequired,
    reservableByGuest: PropTypes.bool,
  }).isRequired,
  rate: PropTypes.shape({
    nightPrice: PropTypes.shape({
      displayWithCode: PropTypes.string.isRequired,
    }).isRequired,
    totalNights: PropTypes.number.isRequired,
    totalPrice: PropTypes.shape({
      display: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onSelect: PropTypes.func,
}

export default Campsite
