import { Col, Row } from 'reactstrap'
import EmailInput from 'Shared/Formik/EmailInput'
import FormTextInput from 'Shared/Formik/FormTextInput'
import InternationalPhone from 'Shared/Formik/InternationalPhone/InternationalPhone'
import TelephoneInput from 'Shared/TelephoneInput/TelephoneInput'

const EMAIL_NOTE = 'Note: Guests without an email will not receive confirmations or reminders'

const ContactInformation = ({ displayEmail, handleEmailOnBlur, inputStyles, title, useInternationalPhone }) => (
  <>
    {title && (
      <h6 className="fw-bold mb-4">{title}</h6>
    )}

    <Row>
      <Col lg={6}>
        <FormTextInput {...inputStyles} isRequired label="First Name" name="contact.firstName"
                       placeholder="First Name" showErrors />
      </Col>

      <Col lg={6}>
        <FormTextInput {...inputStyles} isRequired label="Last Name" name="contact.lastName"
                       placeholder="Last Name" showErrors />
      </Col>
    </Row>

    {displayEmail && (
      <Row>
        <Col>
          <EmailInput {...inputStyles} helpText={EMAIL_NOTE}
                      helpTextClasses="fst-italic opacity-75 text-smaller-1" label="Email (optional)"
                      name="contact.email" onBlur={handleEmailOnBlur} showErrors validateWithSchema />
        </Col>
      </Row>
    )}

    {useInternationalPhone ? (
      <InternationalPhone {...inputStyles} isRequired label="Phone" name="contact.phone" showErrors />
    ) : (
      <TelephoneInput {...inputStyles} nameScope="contact." />
    )}
  </>
)

ContactInformation.defaultProps = {
  displayEmail: false,
  handlerEmailBlur: () => {},
  useInternationalPhone: false,
}

export default ContactInformation
