import { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import ArrowButton from './ArrowButton/ArrowButton'
import './ScrollableList.scss'

const PIXELS_TO_MOVE_SCROLL = 200

const ScrollableList = ({ children, items }) => {
  const [state, setState] = useState({ isEnd: false, isStart: true })
  const { isEnd, isStart } = state
  const scrollArea = useRef()

  const moveToLeft = () => moveScroll(-PIXELS_TO_MOVE_SCROLL)
  const moveToRigth = () => moveScroll(PIXELS_TO_MOVE_SCROLL)

  const moveScroll = amount => {
    scrollArea.current.scrollLeft += amount
  }

  const onScroll = () => {
    if (!scrollArea.current) return

    const scrollableAreaWidth = scrollArea.current.offsetWidth
    const currentScrollLeft = scrollArea.current.scrollLeft
    const spaceAvailableToScroll = Math.floor(scrollArea.current.scrollWidth - currentScrollLeft)

    setState({ isEnd: spaceAvailableToScroll <= scrollableAreaWidth, isStart: currentScrollLeft === 0 })
  }

  useEffect(() => {
    onScroll()
  }, [])

  return (
    <div className="align-items-center d-flex scrollable-list position-relative">
      {!isStart && (
        <ArrowButton className="arrow-previous" icon="chevron-left" onClick={moveToLeft} />
      )}

      <ul className="align-items-center d-flex p-0" onScroll={onScroll} ref={scrollArea}>
        {items.map(item => (
          <li key={item.id || nanoid()}>
            {children(item)}
          </li>
        ))}
      </ul>

      {!isEnd && <ArrowButton className="arrow-next" icon="chevron-right" onClick={moveToRigth} />}
    </div>
  )
}

ScrollableList.defaultProps = {
  items: [],
}

ScrollableList.propTypes = {
  children: PropTypes.func.isRequired,
  items: PropTypes.oneOfType([PropTypes.array]),
}

export default ScrollableList
