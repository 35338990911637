import { memo } from 'react'
import cnames from 'classnames'
import Step from './Step'
import 'Shared/ProgressNav/ProgressNav.scss'

const ProgressNav = ({ currentRoute, goToRoute, hideStepNumber, isClickable, progressNavClass, size, steps,
                       stepsToHide, theme }) => {
  const totalSteps = steps.length

  const currentStep = steps.find(item => item?.routes.includes(currentRoute))

  const isLastStep = totalSteps === currentStep?.index

  const isActive = step => (step?.index <= currentStep?.index) && 'active'

  const isCurrent = step => (step?.index === currentStep?.index) && 'current'

  const stepsToShow = steps.filter(({ index }) => !stepsToHide.includes(index))

  const classes = {
    progressNav: cnames('progress-nav d-print-none', progressNavClass, theme),
    step: step => cnames('align-items-center flex-column d-flex step', isActive(step), isCurrent(step), size),
  }

  return (
    <div className={classes.progressNav}>
      {stepsToShow.map((step, index) => {
        const route = step.routes[0]
        const navigateToRoute = () => goToRoute(route)
        if (steps.length > stepsToShow.length) step.index = index + 1

        return (
          <div className={classes.step(step)} key={step.index}>
            <Step {...{ navigateToRoute, hideStepNumber, isClickable, isLastStep, size, step }}
                  isActive={isActive(step)} />
          </div>
        )
      })}
    </div>
  )
}

ProgressNav.defaultProps = {
  hideStepNumber: false,
  stepsToHide: [],
  isClickable: true,
  progressNavClass: '',
  size: '',
  theme: '',
}

export default memo(ProgressNav)
