import { useState } from 'react'
import storeConnect from 'Checkout/Redux/Connect'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import cnames from 'classnames'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import Anchor from 'Shared/Anchor/Anchor'
import LightboxImages from 'Checkout/Components/Shared/LightboxImages'
import NoticesModal from 'Checkout/Components/Shared/ViewButtons/Modals/NoticesModal'
import PoliciesWrap from 'Checkout/Components/Shared/PoliciesWrap'
import ViewModal from 'Shared/ViewModal/ViewModal'
import './ViewButtons.scss'

const ViewButtons = ({ alerts, alertsModal, className, isNonRefundable, mapUrl, pictureUrls, reservationPolicy,
                       setAlertsModal }) => {
  const [mapModal, setMapModal] = useState(false)
  const [picturesModal, setPicturesModal] = useState(false)
  const [policiesModal, setPoliciesModal] = useState(false)
  const classes = {
    container: cnames('d-flex view-buttons align-items-center', className),
    button: 'button-view bg-transparent p-lg-2 p-1 rounded-0 text-center text-decoration-none text-primary fw-bold',
  }
  const photosLength = pictureUrls?.length ?? 0

  const toggleMapModal = () => setMapModal(!mapModal)

  const toggleNoticesModal = () => setAlertsModal(!alertsModal)

  const togglePicturesModal = () => setPicturesModal(!picturesModal)

  const togglePoliciesModal = () => setPoliciesModal(!policiesModal)

  return (
    <div className={classes.container}>
      {photosLength > 0 && (
        <>
          <Anchor className={classes.button} onClick={togglePicturesModal}>
            {`(${photosLength}) ${pluralize('Photo', photosLength)}`}
          </Anchor>

          {picturesModal && <LightboxImages closeLightbox={togglePicturesModal} images={pictureUrls} />}
        </>
      )}

      <NoticesModal />

      {alerts.length > 0 && (
        <Anchor className={classes.button} onClick={toggleNoticesModal}>Notices</Anchor>
      )}

      {!!mapUrl && (
        <>
          <Anchor className={classes.button} onClick={toggleMapModal}>Map</Anchor>

          {mapModal && <LightboxImages closeLightbox={toggleMapModal} images={[mapUrl]} />}
        </>
      )}

      <Anchor className={classes.button} onClick={togglePoliciesModal} href="#policies">Policies</Anchor>

      <ViewModal isOpen={policiesModal} title="Policies" toggle={togglePoliciesModal}>
        <PoliciesWrap applyReservationPolicy={isNonRefundable} reservationPolicy={reservationPolicy} />
      </ViewModal>
    </div>
  )
}

ViewButtons.defaultProps = {
  mapUrl: null,
  pictureUrls: [],
}

ViewButtons.propTypes = {
  alertsModal: PropTypes.bool.isRequired,
  mapUrl: PropTypes.string,
  pictureUrls: PropTypes.arrayOf(PropTypes.string),
  setAlertsModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  alerts: state.campground.alerts,
  alertsModal: state.campground.alertsModal,
  isNonRefundable: state.payment.isNonRefundable,
  mapUrl: state.campground.mapUrl,
  reservationPolicy: state.payment?.nonRefundableDisclaimer,
  pictureUrls: state.campground.pictureUrls,
})

const mapDispatchToProps = dispatch => ({
  setAlertsModal: alertsModal => dispatch(actionCampgroundSetData({ alertsModal })),
})

export default storeConnect({ mapStateToProps, mapDispatchToProps })(ViewButtons)
