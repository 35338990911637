import PropTypes from 'prop-types'

const DiscountOptions = ({ discounts }) => discounts.map(discount => (
  <option key={discount.id} value={discount.id}>{discount.clubName} ({discount.rate}%)</option>
))

DiscountOptions.defaltProps = {
  discounts: [],
}

DiscountOptions.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
}

export default DiscountOptions
