import { gql } from 'Utils/apolloClient'
import PricingDataFragment from './PricingDataFragment'

const ReservationDataFragment = gql`
  fragment reservationData on Reservation {
    autoAssign
    balanceDue {
      amount
      display
      displayWithCode
    }
    cancellationPolicy
    confirmationMessage
    confirmationTitle
    customFieldValues {
      answer
      question
    }
    id
    isApproved
    email
    firstName
    formattedFrom
    formattedTo
    installmentsCount
    installmentsPaidCount
    installmentsNotPayableCount
    isNonRefundable
    isPurchasePending
    isTripInsuranceAvailable
    name
    phone {
      country
      number
      display
    }
    from
    to
    data
    discount {
      id
      clubName
      rate
    }
    links {
      accountReservationUrl
    }
    rate {
      id
      rigRequired
      siteTypeName
      totalNights
      totalPrice  {
        amount
        displayWithCode
      }
    }
    site {
      id
      name
    }
    guests {
      adults
      children
      pets
      useGuests
      vehicles
      vehiclesTowed
    }
    installmentsNotPayable {
      number
      processingFeeTotal {
        amount
        display
        displayWithCode
      }
      taxTotal {
        amount
        display
        displayWithCode
      }
      total {
        amount
        display
        displayWithCode
      }
      state
    }
    marketplace
    lastName
    rig {
      numSlideouts
      rigLength
      rigType
      rigWidth
      year
    }
    pricing {
      ...pricingData
    }
    state
    hasTripInsurance
    totalWithTripInsurance {
      displayWithCode
    }
    tripInsurancePrice {
      display
    }
    tripInsurancePolicy {
      grossPremium {
        display
        displayWithCode
      }
      number
    }
    tripInsuranceQuote
    uuid
  }

  ${PricingDataFragment}
`

export default ReservationDataFragment
