const HeaderCell = ({ header }) => {
  const className = `col-${header.cols || 1}`

  return (
    <div className={className}>
      <span>{header.label}</span>
    </div>
  )
}

export default HeaderCell
