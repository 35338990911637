import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import cnames from 'classnames'
import RichText from 'Shared/RichText/RichText'

const AlertErrors = ({ className, classNameList, errors, graphQLErrors, withScroll }) => {
  const errorRef = useRef()
  const classes = {
    container: cnames(className),
    list: cnames('m-0 ps-4', classNameList),
  }
  const innerErrors = Array.isArray(errors) ? errors : Object.values(errors)

  useEffect(() => {
    if (errorRef.current && withScroll)
      errorRef.current.scrollIntoView()
  }, [errors, errorRef, graphQLErrors])

  return (
    <Alert className={classes.container} color="danger" innerRef={errorRef}>
      <ul className={classes.list}>
        {graphQLErrors?.graphQLErrors?.map(({ message }) => (
          <RichText className="text-gray fw-bold" key={message} html={message} tag="li" />
        ))}

        {innerErrors.map(error => (
          <RichText className="text-gray fw-bold" key={error} html={error} tag="li" />
        ))}
      </ul>
    </Alert>
  )
}

AlertErrors.defaultProps = {
  errors: [],
  graphQLErrors: null,
  withScroll: false,
}

AlertErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  graphQLErrors: PropTypes.shape({}),
  withScroll: PropTypes.bool,
}

export default AlertErrors
