import { Alert } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const EmptyTable = ({ icon, message, secondaryMessage, useEmptyComponent }) => (
  <>
    {(useEmptyComponent) ? (
      <div className="bg-light d-flex flex-column align-items-center text-muted pb-4 pt-5 mb-4">
        <FontAwesomeIcon className="my-2 d-block" icon={icon} size="3x" />

        <h5 className="mb-2">{message}</h5>
        <h6 className="mb-3 fw-normal">{secondaryMessage}</h6>
      </div>
    ) : (
      <Alert className="mt-5" color="warning">
        <FontAwesomeIcon className="text-warning me-2" icon={icon} />
        {message}
      </Alert>
    )}
  </>
)

EmptyTable.defaultProps = {
  icon: ['far', 'flag'],
}

export default EmptyTable
