const getTouchedObj = errors => {
  const touched = {}
  Object.keys(errors).forEach(key => {
    if (Array.isArray(errors[key]))
      touched[key] = errors[key].map(val => {
        if (!val) return null

        return getTouchedObj(val)
      })
    else if (touched[key] && typeof errors[key] !== 'string')
        touched[key] = getTouchedObj(errors[key])
    else
      touched[key] = true
  })

  return touched
}

export default getTouchedObj
