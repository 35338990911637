import { gql } from 'Utils/apolloClient'

const discountsQuery = gql`
  query Discounts($from: ISO8601Date!, $isPos: Boolean,  $rateId: ID, $slug: String!, $to: ISO8601Date!) {
    campground(from: $from, isPos: $isPos, slug: $slug, to: $to) {
      id
      rate(id: $rateId) {
        id
        discounts {
          id
          active
          clubName
          numberRequired
          rate
        }
      }
    }
  }`

export default discountsQuery
