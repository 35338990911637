export const reservationState = {
  campgroundName: '',
  campgroundId: '',
  campsites: {
    data: null,
    error: null,
    loading: false,
  },
  cart: {
    reservationDetails: false,
    reservationPricing: false,
    reservationInstallmentsPaid: false,
  },
  devsOnly: false,
  editedData: null,
  isAdmin: false,
  isFormikError: false,
  nights: null,
  paymentType: 'card',
  pricingResult: {
    error: null,
    loading: false,
  },
  result: {
    data: null,
    error: null,
    loading: false,
  },
  slug: '',
  userId: null,
  uuid: '',
}
