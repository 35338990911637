import cnames from 'classnames'
import PropTypes from 'prop-types'

const RowCell = ({ header, item }) => {
  const { cellName, cols, getValue, name } = header
  const className = cnames('row-cell text-break', `row-cell--${cellName || name}`, `col-${cols || 1}`)
  const value = getValue ? getValue(item[name], item) : item[name]

  return (
    <div className={className}>
      {value}
    </div>
  )
}

RowCell.propTypes = {
  header: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({}).isRequired,
}

export default RowCell
