import { createMiddleware, createMetaReducer } from 'redux-beacon'
import Segment from '@redux-beacon/segment'
import eventsMapperSegment from '../Reducers/TrackSegment'

const segment = Segment()

const segmentMiddleware = createMiddleware(eventsMapperSegment, segment)
const segmentMetaReducer = createMetaReducer(eventsMapperSegment, segment)

export { segmentMiddleware, segmentMetaReducer }
