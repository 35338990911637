import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { asyncQuery } from 'Utils/apolloClient'
import asyncThunkHandler from 'Admin/Store/asyncThunkHandler'
import inventoryHoldQuery from 'Shared/CalendarQuickEdit/Queries/InventoryHoldQuery'
import reservationQuery from 'Shared/CalendarQuickEdit/Queries/ReservationQuery'
import sitesAvailableDuringQuery from 'Shared/CalendarQuickEdit/Queries/SitesAvailableDuringQuery'
import { calendarQuickEditInitialState,
         INITIAL_STATE_QUERY } from 'Shared/CalendarQuickEdit/Store/calendarQuickEditInitialState'

export const getInventoryHold = createAsyncThunk(
  'calendarQuickEdit/getInventoryHold',
  asyncThunkHandler(async ({ variables }) => {
    const { data } = await asyncQuery(inventoryHoldQuery)({ variables })
    return data
  })
)

export const getReservation = createAsyncThunk(
  'calendarQuickEdit/getReservation',
  asyncThunkHandler(async ({ variables }) => {
    const { data } = await asyncQuery(reservationQuery)({ variables })
    return data
  })
)

export const getSitesAvailableDuring = createAsyncThunk(
  'calendarQuickEdit/getSitesAvailableDuring',
  asyncThunkHandler(async ({ variables }) => {
    const { data } = await asyncQuery(sitesAvailableDuringQuery)({ variables })
    return data
  })
)

const calendarQuickEdit = createSlice({
  name: 'calendarQuickEdit',
  initialState: calendarQuickEditInitialState,
  reducers: {
    setDatesValues: (state, { payload }) => {
      state.formValues = payload
    },
  },
  extraReducers: {
    [getInventoryHold.pending]: state => {
      state.inventoryHoldQuery.called = true
      state.inventoryHoldQuery.loading = true

      state.reservationQuery = INITIAL_STATE_QUERY
    },
    [getInventoryHold.fulfilled]: (state, { payload }) => {
      const { inventoryHold, ...campground } = payload.campground

      state.inventoryHoldQuery.loading = false

      state.campground = campground
      state.reservation = inventoryHold
      state.translates = payload.translates
    },
    [getInventoryHold.rejected]: (state, { error }) => {
      state.inventoryHoldQuery.error = error
      state.inventoryHoldQuery.loading = false
    },
    [getReservation.pending]: state => {
      state.reservationQuery.called = true
      state.reservationQuery.loading = true

      state.inventoryHoldQuery = INITIAL_STATE_QUERY
    },
    [getReservation.fulfilled]: (state, { payload }) => {
      const { reservation, ...campground } = payload.campground

      state.reservationQuery.loading = false

      state.campground = campground
      state.reservation = reservation
      state.translates = payload.translates
    },
    [getReservation.rejected]: (state, { error }) => {
      state.reservationQuery.error = error
      state.reservationQuery.loading = false
    },
    [getSitesAvailableDuring.pending]: state => {
      state.sitesAvailableDuringQuery.called = true
      state.sitesAvailableDuringQuery.loading = true
    },
    [getSitesAvailableDuring.fulfilled]: (state, { payload }) => {
      state.sitesAvailableDuringQuery.data.sitesAvailableDuring = payload?.campground?.sitesAvailable
      state.sitesAvailableDuringQuery.data.rateSitesAvailable = payload?.campground?.rate?.sitesAvailableDuring
      state.sitesAvailableDuringQuery.loading = false
    },
    [getSitesAvailableDuring.rejected]: (state, { error }) => {
      state.sitesAvailableDuringQuery.error = error
      state.sitesAvailableDuringQuery.loading = false
    },
  },
})

export const {
  setDatesValues,
} = calendarQuickEdit.actions

export default calendarQuickEdit.reducer
