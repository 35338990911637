const optionsReducer = (currentState = {}, { type, payload }) => {
    const handler = optionsHandlers[type]
    if (handler) return handler(currentState, payload)

    return currentState
  }

const optionsSetData = (currentState, payload = {}) => ({
  ...currentState,
  ...payload,
})

const updateURLs = (currentState, payload) => ({
  ...currentState,
  urls: [...currentState.urls, payload],
})

const optionsHandlers = {
  OPTIONS_SET_DATA: optionsSetData,
  UPDATE_URLS: updateURLs,
}

export default optionsReducer
