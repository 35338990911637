import { dateWithSlashes, formatDate, hourWithTimezone } from 'Utils/Dates'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Tooltip from 'Shared/Tooltip'
import EventTitle from './EventTitle'
import InvoiceData from './InvoiceData'
import GroupedChanges from './GroupedChanges'
import { eventMaping } from './eventMaping'

const CardEvent = ({ event }) => {
  const { amount, changesHumanized, dataChanges, id, installmentLink, installmentNumber, itemTotal, name,
          occurredAt, changesMoney, type, userEmail } = event
  const autor = userEmail || 'Auto'
  const config = eventMaping[type][name]
  const isGroupChanges = ['amountChanged', 'billingDayChanged', 'revision', 'overridenRate'].includes(name)
  const itemCreatedOrDeleted = ['itemCreated', 'itemDeleted'].includes(name)
  const invoiceAmount = itemCreatedOrDeleted ? itemTotal : amount
  const scheduleItemCreatedOrRemoved = type === 'EventSchedule' && itemCreatedOrDeleted

  return (
    <Card className="card-event">
      <CardHeader className="d-flex flex-nowrap justify-content-between">
        <span className="autor">{autor}</span>

        <span className="ms-1 text-muted" id={`event-${id}-date`}>
          {formatDate(occurredAt, { format: dateWithSlashes })}
        </span>

        <Tooltip idTarget={`event-${id}-date`} placement="top">
          {formatDate(occurredAt, { format: hourWithTimezone })}
        </Tooltip>
      </CardHeader>

      <CardBody>
        {isGroupChanges ? (
          <GroupedChanges changes={changesMoney || dataChanges} eventConfig={config} id={id} />
        ) : (
          <>
            <EventTitle icon={config?.icon} label={config?.label || changesHumanized} link={installmentLink} />

            {(installmentNumber || scheduleItemCreatedOrRemoved) && (
              <InvoiceData {...event} amount={invoiceAmount} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default CardEvent
