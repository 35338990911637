import classNames from 'classnames'
import storeConnect from 'Checkout/Redux/Connect'
import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import { searchFilterUpdate } from 'Checkout/Redux/Actions/Search'
import Anchor from 'Shared/Anchor/Anchor'
import './SignInLink.scss'

const SignInLink = ({ camperName, className, setSignInModal, signInModal, text }) => {
  const toggleSignInModal = () => { if (!camperName) setSignInModal(!signInModal) }

  const classes = classNames('align-items-center justify-content-end sign-in-link', className)
  const spanClass = camperName ? 'd-sm-block' : 'd-md-block'
  const spanText = camperName || text

  return (
    <>
      <Anchor className={classes} onClick={toggleSignInModal}>
        <FontAwesomeIcon className="icon-user me-2" icon="user" />
        <span className={`d-none ${spanClass}`}>{spanText}</span>
      </Anchor>
    </>
  )
}

SignInLink.defaultProps = {
  text: 'Sign into RoverPass',
}

const mapDispatchToProps = dispatch => ({
  setSignInModal: signInModal => dispatch(searchFilterUpdate({ signInModal })),
})

const mapStateToProps = state => ({
  camperName: state.camper.name,
  signInModal: state.search.signInModal,
})

export default storeConnect({ mapDispatchToProps, mapStateToProps })(SignInLink)
