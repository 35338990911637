import Loading from 'Shared/Loading'
import Table from 'Shared/Admin/Table/Table'
import EmptyTable from 'Shared/Admin/Table/EmptyTable'

const WrapperTable = props => {
  const { children, data, emptyMessage, emptyIcon, loading, useEmptyComponent } = props

  if (loading) return <Loading className="d-flex justify-content-center py-5" />

  return (
    <>
      {children}

      {(!data?.length) ? (
        <EmptyTable icon={emptyIcon} message={emptyMessage} useEmptyComponent={useEmptyComponent} />
      ) : (
        <Table {...props} />
      )}
    </>
  )
}

export default WrapperTable
