import cnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'

const CollapseHeader = ({ className, handleToggle, isOpen, title, value }) => {
  const classes = cnames('d-flex justify-content-between align-items-center', className ?? 'px-2')

  return (
    <div className={classes}>
      <p className="m-0 text-blue">{title}</p>
      <div className="d-flex align-items-center">
        <h6 className="m-0 text-blue">{value}</h6>

        <Button color="link" className="reservation-link text-blue px-1" onClick={handleToggle}>
          <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
        </Button>
      </div>
    </div>
  )
}

CollapseHeader.defaultProps = {
  value: '$0.00',
}

CollapseHeader.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default CollapseHeader
