import { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker as ReactDateRangePicker } from 'react-dates'
import { dateBetween, parseMoment, today } from 'Utils/Dates'
import { useEffectAfterMount } from 'Shared/Hooks/useEffectAfterMount'
import calendarIcon from 'Checkout/Images/Icons/calendar.svg'
import 'react-dates/lib/css/_datepicker.css'
import '../DatePicker.scss'
import './DateRangePicker.scss'

const HORIZONTAL_ORIENTATION = 'horizontal'
const LARGE_HEIGHT = 850
const LARGE_WIDTH = 996
const VERTICAL_ORIENTATION = 'vertical'

const DateRangePicker = ({ checkInId, checkInPlaceholder, checkInValue, checkOutId, checkOutPlaceholder,
                           checkOutValue, closureDates, disabled, format, isDateError, isPos, maxDate, minDate,
                           minimumNights, numberOfMonths, onDateSet, openDirection, showDefaultInputIcon,
                           updateDates, withIds }) => {
  // TODO: Refactor these states with a useReducer for less renders
  const [checkIn, setCheckIn] = useState()
  const [checkOut, setCheckOut] = useState()
  const [focusedInput, setFocusedInput] = useState()
  const [detectedNumberOfMonths, setDetectedNumberOfMonths] = useState()
  const [detectedOrientation, setDetectedOrientation] = useState()
  const [ids, setIds] = useState({ startDateId: checkInId, endDateId: checkOutId })
  const [initialMonth, setInitialMonth] = useState()
  // NOTE: Don't move this two vars to default props because moment won't take the correct Date (Test only)
  const maxDateProp = maxDate ?? today().add(3, 'years')
  const minDateProp = minDate ?? today()

  const customIcons = [<img src={calendarIcon} className="mx-1" height="25" key={1} alt="Date Picker" />]

  const updateNumberOfMonths = () => {
    const widthScreen = window.innerWidth
    if (widthScreen < LARGE_WIDTH) return updateLayoutOrientation()

    setDetectedNumberOfMonths(2)
    setDetectedOrientation(HORIZONTAL_ORIENTATION)
  }

  const updateLayoutOrientation = () => {
    const isHeightLarge = window.innerHeight > LARGE_HEIGHT
    const orientation = isHeightLarge ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
    const months = isHeightLarge ? 2 : 1

    setDetectedOrientation(orientation)
    setDetectedNumberOfMonths(months)
  }

  const updatePath = ({ startDate, endDate }) => updateDates({ startDate, endDate })

  const handleDataChange = ({ startDate, endDate }) => {
    setDates(startDate, endDate)
    if (!onDateSet) return updatePath({ startDate, endDate })

    onDateSet({ startDate, endDate })
  }

  const isDayBlocked = day => {
    if (!closureDates || !closureDates.length) return false

    return closureDates.some(range => dateBetween(day, { from: range.from, to: range.to }))
  }

  const isCampgroundClosedOn = (from, to) => {
    if (!closureDates || !closureDates.length) return false

    return closureDates.some(({ from: closureFrom, to: closureTo }) => (
      dateBetween(closureFrom, { from, to }) || dateBetween(closureTo, { from, to })
    ))
  }

  const isOutsideRange = day => !dateBetween(day, { from: minDateProp, to: maxDateProp })

  const setDates = (startDate, endDate) => {
    if (startDate && !isDayBlocked(startDate)) setCheckIn(parseMoment(startDate))
    // Note: in case that maxDate was dynamic we need to review that the endDate saved/selected is valid

    const checkOutDate = validEndDate(startDate, endDate) ? parseMoment(endDate) : null
    setCheckOut(checkOutDate)
  }

  const validEndDate = (startDate, endDate) => {
    if (!endDate) return false
    const endDateValid = parseMoment(endDate) <= maxDateProp

    return (endDateValid && !isDayBlocked(endDate) && !isCampgroundClosedOn(startDate, endDate))
  }

  const focusChange = focusedInput => {
    const focusButtonDate = focusedInput === 'startDate' ? checkIn : checkOut
    if (!isPos && checkIn) setCheckOut(null)
    setFocusedInput(focusedInput)
    setInitialMonth(focusButtonDate)
  }

  const resetDates = () => {
    setCheckIn(null)
    setCheckOut(null)
  }

  useLayoutEffect(() => {
    updateNumberOfMonths()

    if (!withIds) setIds({ startDateId: '', endDateId: '' })
  }, [])

  useEffect(() => {
    if (!checkInValue && !checkOutValue) resetDates()
    else if (checkInValue || checkOutValue) setDates(checkInValue, checkOutValue)
  }, [checkInValue, checkOutValue])

  useEffect(() => {
    window.addEventListener('resize', updateNumberOfMonths)
    setCheckIn(parseMoment(checkInValue))
    setCheckOut(parseMoment(checkOutValue))

    return () => window.removeEventListener('resize', updateNumberOfMonths)
  }, [])

  useEffectAfterMount(() => {
    if (isDateError) setFocusedInput(null)
  }, [isDateError])

  return (
    <ReactDateRangePicker disabled={disabled} startDate={checkIn} endDate={checkOut} startDateId={ids.startDateId}
                          endDateId={ids.endDateId} startDatePlaceholderText={checkInPlaceholder}
                          endDatePlaceholderText={checkOutPlaceholder} onDatesChange={handleDataChange}
                          focusedInput={focusedInput} onFocusChange={focusChange}
                          initialVisibleMonth={() => initialMonth || today()} minimumNights={minimumNights}
                          numberOfMonths={numberOfMonths || detectedNumberOfMonths} openDirection={openDirection}
                          orientation={detectedOrientation} isOutsideRange={isOutsideRange} maxDate={maxDateProp}
                          minDate={minDateProp} horizontalMargin={50} block
                          showDefaultInputIcon={showDefaultInputIcon}
                          customInputIcon={showDefaultInputIcon ? null : customIcons} displayFormat={format}
                          isDayBlocked={isDayBlocked} />
  )
}

DateRangePicker.defaultProps = {
  checkInId: 'check_in',
  checkInPlaceholder: 'Check In',
  checkInValue: null,
  checkOutId: 'check_out',
  checkOutPlaceholder: 'Check Out',
  checkOutValue: null,
  closureDates: [],
  disabled: false,
  format: 'ddd, MMM. Do',
  isPos: false,
  maxDate: null,
  minDate: null,
  minimumNights: 1,
  numberOfMonths: null,
  onDateSet: null,
  openDirection: 'down',
  showDefaultInputIcon: false,
  withIds: true,
}

DateRangePicker.propTypes = {
  checkInId: PropTypes.string,
  checkInPlaceholder: PropTypes.string,
  checkInValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  checkOutId: PropTypes.string,
  checkOutPlaceholder: PropTypes.string,
  checkOutValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  closureDates: PropTypes.arrayOf(PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  })),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  format: PropTypes.string,
  isPos: PropTypes.bool,
  maxDate: PropTypes.shape({}), // NOTE: this is a moment object
  minDate: PropTypes.shape({}), // NOTE: this is a moment object
  minimumNights: PropTypes.number,
  numberOfMonths: PropTypes.number,
  onDateSet: PropTypes.func,
  openDirection: PropTypes.oneOf(['down', 'up']),
  showDefaultInputIcon: PropTypes.bool,
  updateDates: PropTypes.func.isRequired,
  withIds: PropTypes.bool,
}

export default DateRangePicker
