import { useEffect, useState } from 'react'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { Button, Modal, ModalBody } from 'reactstrap'
import { ReactSVG } from 'react-svg'
import externalLinksQuery from 'Shared/LogInModal/Mutations/externalLinksQuery'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import Link from 'Shared/Link/Link'
import LogInForm from 'Shared/LogInModal/Components/LogInForm/LogInForm'
import RvIcon from 'Shared/Assets/roverpass_logo.svg'
import SingUpForm from 'Shared/LogInModal/Components/SingUpForm/SingUpForm'

const LogInModal = ({ isOpen, toggle }) => {
  const [formActive, setFormActive] = useState('LogIn')
  const [externalLinks, setExternalLinks] = useState({})
  const [getExternalLinks, { loading, data }] = useLazyQueryNoCache(externalLinksQuery)
  const isLogInFormActive = formActive === 'LogIn'

  const formToggle = () => { isLogInFormActive ? setFormActive('SignUp') : setFormActive('LogIn') }

  const closeModal = () => {
    setFormActive('LogIn')
    toggle()
  }

  useEffect(() => {
    if (!loading && !data) getExternalLinks()
  }, [])

  useEffect(() => {
    if (data) setExternalLinks(data.options.externalLinks)
  }, [data])

  const buttonText = isLogInFormActive ? "Don't have and account? Sign up here" : 'Sign Up'
  const title = isLogInFormActive ? 'Welcome Back!' : 'Start Camping!'

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal}>
      <ModalBody>
        <div className="d-flex align-items-center flex-column pt-2">
          <ReactSVG className="roverpass-icon" src={RvIcon} />

          <h4 className="my-2">{title}</h4>
        </div>

        {isLogInFormActive ? <LogInForm closeModal={closeModal} formToggle={formToggle} /> :
                             <SingUpForm closeModal={closeModal} /> }

        <div className="d-flex align-items-center flex-column mt-4">
          <Link className="text-bold text-salmon" isExternal to={externalLinks.forgotPassword}>
            Forgot your password?
          </Link>

          <div className="my-4 py-4 border-top border-bottom">
            <Link className="btn btn-facebook d-flex align-items-center" isExternal to="facebook">
              <FontAwesomeIcon className="col-4" icon={['fab', 'facebook-f']} />

              <span className="col-8 text-start">Login with Facebook</span>
            </Link>

            <div className="text-smaller-2 text-black-50 text-center">
              We hate span on social networks, so we won't publish anything without your permission.
            </div>
          </div>

          <Button className="text-bold text-salmon" color="link" onClick={formToggle}>
            {buttonText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LogInModal
