import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { gaMiddleware, gaMetaReducer } from './Trackers/TrackGA'
import { segmentMiddleware, segmentMetaReducer } from './Trackers/segment.io'
import campground from './Reducers/Campground'
import camper from './Reducers/Camper'
import details from './Reducers/Details'
import errors from './Reducers/Errors'
import options from './Reducers/Options'
import payment from './Reducers/Payment'
import search from './Reducers/Search'
import initialState from './InitialState'

const reducers = combineReducers({
  search, camper, campground, details, errors, options, payment, gaMetaReducer, segmentMetaReducer,
})

const middlewares = [thunk, gaMiddleware]
if (window.analytics) middlewares.push(segmentMiddleware)
// disable dev tools in staging & production environments and use the `compose` as fallback
const composeEnhancers = (!['production', 'staging'].includes(process.env.NODE_ENV) &&
                          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)))

export default store
