let DetailsReducer

(() => {
  DetailsReducer = (currentState = {}, { type, payload }) => {
    const handler = detailsHandlers[type]
    if (handler) return handler(currentState, payload)

    return currentState
  }

  const detailsSetData = (currentState, payload = {}) => ({
    ...currentState,
    ...payload,
  })

  const detailsHandlers = {
    DETAILS_SET_DATA: detailsSetData,
  }
})()
export default DetailsReducer
