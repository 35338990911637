import cnames from 'classnames'
import PropTypes from 'prop-types'
import Campsite from './Campsite'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import './Campsite.scss'

const Rate = ({ isFlexible, isMinNightsMuted, onSelect, rate, site, sourceRequired }) => {
  const handleSelectCampsite = campsite => onSelect && onSelect({ campsite, rate, sourceRequired })
  const minNights = rate?.minNightsRequired || 0
  const minNightsClass = cnames('min-nights', { 'opacity-50': isMinNightsMuted })

  return (
    <article className="campsite">
      <div className="campsite__toggle">
        <div className="campsite__info d-flex justify-content-between aligin-items-center w-100">
          <h5 className="campsite__title">{rate.name}</h5>

          {minNights > 1 && (
            <div className={minNightsClass}>
              <FontAwesomeIcon className="me-2" color="#1F4965" icon={['far', 'moon']} />

              <span>{minNights} Nights Min</span>
            </div>
          )}
        </div>
      </div>

      <div className="campsite__list">
        {!site && rate.sites.map(campsite => (
          <Campsite campsite={campsite} isFlexible={isFlexible} key={`rate-${rate.id}-site-${campsite.id}`}
                    onSelect={handleSelectCampsite} rate={rate} />
        ))}

        {site && <Campsite added campsite={site} isFlexible={isFlexible} rate={rate} />}
      </div>
    </article>
  )
}

Rate.defaultProps = {
  onSelect: () => {},
  site: null,
  sourceRequired: false,
}

Rate.propTypes = {
  onSelect: PropTypes.func,
  rate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  sourceRequired: PropTypes.bool,
}

export default Rate
