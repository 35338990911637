import { gql } from 'Utils/apolloClient'

const ValidationDataFragment = gql`
  fragment validationData on Validations {
    id
    areGuestsRequired
    isRigRequired
    guests {
      additionalNotes
      adults{
        ageRange
        max
        min
      }
      children {
        ageRange
        max
        min
      }
      maxPeople
      extraVehicles {
        max
        min
      }
      pets {
        max
        min
      }
      useGuests
    }
    rig {
      slideouts {
        max
        min
      }
      type
      year {
        max
        min
      }
      length {
        max
        min
      }
      width {
        max
        min
      }
    }
  }`

export default ValidationDataFragment
