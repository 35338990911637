const initialState = {
  search: {
    accommodation: null,
    checkIn: null,
    checkOut: null,
    dayUse: null,
    rate: null,
    rigWidth: null,
    rigLength: null,
    siteType: null,
    site: null,
    filters: [],
    rigSizeModal: false,
    signInModal: false,
    siteLockModal: false,
  },

  camper: {
    address: '',
    authentication_token: null,
    createdAt: '',
    stripeCards: [],
    address2: '',
    email: '',
    equipment: [],
    firstName: '',
    id: null,
    lastName: '',
    phone: {
      country: '',
      number: '',
    },
    zipCode: '',
  },

  campground: {
    address: '',
    advertisement: '',
    alerts: [],
    alertsModal: false,
    cancellationPolicyConfig: null,
    campgroundCancellationPolicy: '',
    canUseNonRefundable: false,
    checkInProcedure: '',
    checkInRule: '',
    checkOutRule: '',
    city: '',
    isInstantBooking: false,
    isRefundableFully: false,
    mapUrl: null,
    marketplace: {
      isMarketplace: false,
      referringDomain: null,
    },
    name: '',
    rulesList: null,
    roverpassCancellationPolicy: '',
    sitesMapUrl: null,
    slug: '',
    state: '',
    utmData: null,
    websiteColorPrimary: '',
    websiteColorPrimaryAccent: '',
    websiteColorSecondary: '',
  },

  details: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: {
      country: '+1',
      display: '',
      number: '',
    },
    allowMarketing: true,
    address: '',
    address2: '',
    city: '',
    state: '',
    guests: {
      adults: 0,
      children: 0,
      pets: 0,
      petBreeds: '',
      vehicles: 0,
      vehiclesTowed: false,
    },
    useGuests: false,
    vehicle: {
      id: null,
      length: '',
      slideouts: '',
      type: '',
      year: '',
      width: '',
    },
    productsPurchased: [],
    nonRvTowed: false,
    specialRequests: '',
    zipCode: '',
    customFields: [],
    reservationDetails: {},
    hideTripProtection: false,
  },

  options: {
    externalLinks: {},
    fbAppId: '',
    siteTypes: {},
    urls: [],
  },

  payment: {
    acknowledgement: false,
    address: '',
    address2: '',
    billingAddressChecked: true,
    camperFee: null,
    camperProcessingFee: null,
    camperTotal: {
      amount: null,
      cents: null,
      display: null,
      displayWithCode: null,
    },
    card: {},
    city: '',
    couponCode: null,
    defaultPayment: false,
    discount: {
      code: '',
      id: '',
      numberRequired: false,
    },
    dueNow: null,
    dueUponCheckIn: null,
    homeAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
    },
    isPurchaseComplete: false,
    isSiteLock: false,
    isNonRefundable: false,
    items: [],
    nonRefundableDiscount: null,
    platformFee: null,
    savings: null,
    siteLockFee: null,
    subtotal: null,
    state: '',
    selectedCard: null,
    tax: null,
    taxesAndFees: null,
    totalRevenue: null,
    isTripProtection: false,
    tripProtectionQuote: null,
  },

  errors: {
    globalError: null,
  },
}

export default initialState
