import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { totalGuests } from 'Utils/guestHelpers'
import FormQuantityButtons from 'Shared/Formik/FormQuantityButtons'
import MaxPeopleMessage from 'Shared/MaxPeopleMessage'

const ReservationGuests = ({ displayExtraVehicle, inputStyles, onChange, validations, values }) => {
  const [allowMore, setAllowMore] = useState(true)

  const { areGuestsRequired, guests } = validations
  const { adults, extraVehicles, children, maxPeople, pets, useGuests } = guests
  const adultsLabel = useGuests ? 'Guests' : 'Adults'
  const currentTotalGuests = totalGuests({ adults: values.adults, children: values.children })

  useEffect(() => {
    setAllowMore(currentTotalGuests < maxPeople)
  }, [currentTotalGuests])

  return (
    <>
      <Row className="mb-4">
        <Col lg={6}>
          <FormQuantityButtons {...inputStyles} enabled={allowMore}
                               helperText={useGuests ? '' : `(Ages ${adults.ageRange})`}
                               isRequired={areGuestsRequired} label={adultsLabel} max={adults.max}
                               name="guests.adults" onChange={onChange} />
        </Col>

        {children && !useGuests && (
          <Col lg={6}>
            <FormQuantityButtons {...inputStyles} enabled={allowMore} helperText={`(Ages ${children.ageRange})`}
                                 label="Children" max={children.max} name="guests.children" onChange={onChange} />
          </Col>
        )}

        {pets && (
          <Col lg={6}>
            <FormQuantityButtons {...inputStyles} label="Pets" max={pets.max} name="guests.pets"
                                 onChange={onChange} />
          </Col>
        )}

        {displayExtraVehicle && extraVehicles && (
          <Col lg={6}>
            <FormQuantityButtons {...inputStyles} label="Extra Vehicles" max={extraVehicles.max}
                                 name="guests.vehicles" onChange={onChange} />
          </Col>
        )}
      </Row>

      <MaxPeopleMessage maxPeople={maxPeople} total={currentTotalGuests} />
    </>
  )
}

ReservationGuests.defaultProps = {
  displayExtraVehicle: false,
  onChange: null,
  validations: {
    areGuestsRequired: true,
    guest: {
      adults: {
        ageRange: '',
        max: 0,
      },
      extraVehicles: {
        max: 0,
      },
      children: {
        ageRange: '',
        max: 0,
      },
      maxPeople: 0,
      pets: {
        max: 0,
      },
    },
  },
}

ReservationGuests.propTypes = {
  displayExtraVehicle: PropTypes.bool,
  inputStyles: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  validations: PropTypes.shape({}),
  values: PropTypes.shape({}).isRequired,
}

export default ReservationGuests
