const CampgroundReducer = (currentState = {}, { type, payload }) => {
  const handler = campgroundHandlers[type]
  if (handler) return handler(currentState, payload)

  return currentState
}

const campgroundSetData = (currentState, payload = {}) => ({
  ...currentState,
  ...payload,
})

const campgroundHandlers = {
  CAMPGROUND_SET_DATA: campgroundSetData,
}

export default CampgroundReducer
