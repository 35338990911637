import { identifyUser, trackEvent, trackPageView } from '@redux-beacon/segment'
import { formatDate } from 'Utils/Dates'

const generateDate = date => formatDate(date, { format: 'MM/DD/YYYY' })

const generateProduct = (campground, search) => {
  const { rate } = search

  if (!rate) return {}

  const { checkIn, checkOut, siteType } = search
  const { name: brand, systemType: variant } = campground
  const { totalNights, totalPrice } = rate
  const url = window.location.href

  return {
    brand,
    category: siteType.name,
    checkin: generateDate(checkIn),
    checkout: generateDate(checkOut),
    image_url: rate.pictureUrl,
    name: rate.name,
    price: totalPrice?.display,
    product_id: rate.id,
    quantity: totalNights,
    url,
    variant,
  }
}

const generateDetailsCheckout = (campground, search, payment, payload = {}) => {
  const { camperTotal, tax, totalRevenue } = payment
  const products = generateProduct(campground, search)
  const { discount, name, reservationId } = payload

  return {
    name,
    affiliation: 'RoverPass Checkout',
    checkout_id: reservationId,
    currency: 'USD',
    discount: discount?.display,
    order_id: reservationId,
    products: [products],
    revenue: totalRevenue,
    tax: tax?.display,
    total: camperTotal?.amount,
  }
}

const setCheckoutData = trackEvent((action, _, nextState) => {
  const { campground, search, payment } = nextState
  const properties = generateDetailsCheckout(campground, search, payment, action.payload)

  return { name: action.payload.name, properties }
})

const setCheckoutStepViewed = trackEvent(action => ({
  name: 'Checkout Step Viewed',
  properties: { step: action.payload },
}))

const setIdentifyUser = identifyUser(({ payload }) => {
  const { createdAt, email, firstName, id, lastName } = payload

  return {
    userId: email,
    traits: {
      name: `${firstName} ${lastName}`,
      created_at: createdAt,
      email,
      first_name: firstName,
      id,
      last_name: lastName,
    },
  }
})

const setProductClicked = trackEvent((_, __, nextState) => {
  const { campground, search } = nextState
  const properties = generateProduct(campground, search)

  return {
    name: 'Product Clicked',
    properties,
  }
})

const setPageView = trackPageView(action => ({
  name: action.payload,
}))

const setProductsSearched = trackEvent((action, prevState, nextState) => {
  const { campground, search } = nextState
  const { checkIn, checkOut } = search

  return {
    name: 'Products Searched',
    properties: { query: `${campground.name} | ${generateDate(checkIn)} - ${generateDate(checkOut)}` },
  }
})

const eventsMapperSegment = {
  SET_CHECKOUT_DATA: setCheckoutData,
  SET_CHECKOUT_STEP_VIEWED: setCheckoutStepViewed,
  SET_IDENTIFY_USER: setIdentifyUser,
  SET_PAGE_VIEW: setPageView,
  SET_PRODUCT_CLICKED: setProductClicked,
  SET_PRODUCTS_SEARCHED: setProductsSearched,
}

export default eventsMapperSegment
