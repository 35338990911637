import PropTypes from 'prop-types'
import { FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'
import Tooltip from 'Shared/Tooltip'

const FormTextInput = ({ checked, className, disabled, formGroupClasses, helpText, helpTextClasses, icon,
                         iconClass, iconInputText, id, inputClasses, isRequired, isIconOnStart, label,
                         labelClasses, labelRight, max, min, name, onBlur, onChange, placeholder, rightText,
                         rows, showErrors, sizeInput, tooltip, tooltipId, type, validate }) => {
  const classes = {
    FormGroup: cnames('mb-0', formGroupClasses),
    helpText: cnames('help-text w-100', helpTextClasses),
    icon: cnames(iconClass),
    input: cnames('control-input', inputClasses),
    label: cnames('main-label', labelClasses),
  }

  const iconInput = iconInputText &&
    <InputGroupText htmlFor={name} tag="label" className={classes.groupText}>{iconInputText}</InputGroupText>

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors} validate={validate}>
      {({ field, form, hasErrorClass, isInvalidClass }) => {
        const initValue = field.value ?? ''
        const classesInput = cnames(classes.input, hasErrorClass)

        const handleOnChange = event => {
          const newValue = event.target.value
          if (onChange) return onChange(newValue)
          if (type === 'number' && newValue !== '') return form.setFieldValue(name, parseInt(newValue))
          if (newValue.length <= max) form.setFieldValue(name, newValue)
        }

        return (
          <FormGroup className={classes.FormGroup}>
            {label && (
              <div className="d-flex justify-content-between flex-wrap">
                  <Label className={classes.label} for={name}>
                    {label}
                    {isRequired && <span className="text-danger ms-1">*</span>}

                    {tooltip && (
                      <span className="ms-2 px-1" id={tooltipId}>
                        <FontAwesomeIcon className={classes.icon} icon={icon} />
                        <Tooltip idTarget={tooltipId} value={tooltip} />
                      </span>)}
                  </Label>

                {labelRight}
              </div>
            )}

            {helpText && <RichText className={classes.helpText} html={helpText} />}

            <InputGroup className="flex-nowrap">
              {isIconOnStart && iconInput}

              <Input {...field} bsSize={sizeInput} checked={checked} className={classesInput}
                     disabled={disabled} id={id || name} invalid={isInvalidClass} max={max} min={min}
                     name={name} onBlur={onBlur} onChange={handleOnChange} placeholder={placeholder}
                     rows={rows} type={type} value={initValue} />

              {rightText && (
                <InputGroupText>
                  {rightText}
                </InputGroupText>
              )}

              {!isIconOnStart && iconInput}
          </InputGroup>
          </FormGroup>
        )
      }}
    </FormGroupField>
  )
}

FormTextInput.defaultProps = {
  disabled: false,
  helpText: '',
  helpTextClasses: '',
  icon: null,
  iconClass: '',
  iconInputText: null,
  id: '',
  inputClasses: '',
  isRequired: false,
  isIconOnStart: false,
  labelClasses: '',
  label: '',
  max: Number.MAX_SAFE_INTEGER,
  onBlur: () => {},
  onChange: null,
  placeholder: '',
  rows: 1,
  tooltip: '',
  tooltipId: '',
  type: 'text',
  showErrors: false,
  sizeInput: '',
}

FormTextInput.propTypes = {
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  helpTextClasses: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  iconClass: PropTypes.string,
  iconInputText: PropTypes.string,
  id: PropTypes.string,
  inputClasses: PropTypes.string,
  isIconOnStart: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClasses: PropTypes.string,
  max: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  tooltip: PropTypes.string,
  tooltipId: PropTypes.string,
  type: PropTypes.string,
  showErrors: PropTypes.bool,
  sizeInput: PropTypes.string,
}

export default FormTextInput
