import pluralize from 'pluralize'

export const capitalizeFirstLetter = ([first, ...rest]) => [first.charAt(0).toUpperCase(), ...rest].join('')

export const getInitials = input => {
  const initials = input.match(/\b\w/g)
  return initials ? initials.slice(0, 2).join('').toUpperCase() : ''
}

export const pluralizeCounter = (name, number, displayNumber = false) => {
  const capName = capitalizeFirstLetter(name)
  return pluralize(capName, number, displayNumber)
}

export const slugFor = str => str.toLowerCase().replace(/\W+/g, '-')
