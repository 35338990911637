import { gql } from 'Utils/apolloClient'

const BalanceQuery = gql`
  query BalanceQuery($slug: String!, $uuid: ID!, $isEngineAdmin: Boolean) {
    campground(slug: $slug, isEngineAdmin: $isEngineAdmin) {
      id
      reservation(uuid: $uuid) {
        id
        hasRefundsPending
        installmentsCount
        installmentsDueCount
        installmentsPaidCount
        installmentsPendingCount
        refundsInstallments {
          id
          state
          total {
            display
          }
        }
        balance {
          id
          allVoided
          due {
            amount
            display
          }
          noInstallments
          paid {
            amount
            display
          }
          paidNoRefunded {
            amount
            display
          }
          refunded {
            amount
            display
          }
          remaining {
            amount
            display
          }
          remainingNoDue {
            amount
            display
          }
          total {
            amount
            display
          }
          totalWithVoided {
            amount
            display
          }
          voided {
            amount
            display
          }
        }
        installmentPendingToPay {
          dueOn
          links {
            accountInstallmentNextToPayPath
            installmentPath
          }
          total {
            amount
            display
          }
        }
        links {
          scheduleEditUrl
        }
        schedule {
          amount {
            amount
            display
          }
          dayOrdinalized
          isActive
        }
      }
    }
  }`

export default BalanceQuery
