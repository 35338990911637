import { useEffect } from 'react'
import { useMutation } from 'Shared/Hooks/useApolloClient'
import { useDispatch, useSelector } from 'react-redux'
import signInMutation from './CurrentUserQuery'
import { actionCamperSetData } from 'Checkout/Redux/Actions/Camper'
import { detailsSetData } from 'Checkout/Redux/Actions/Details'
import { setIdentifyUser } from 'Checkout/Redux/Actions/TrackSegment'
import { setCookie } from 'Utils/Cookies'

const SIX_HOURS = 6 * 60

const useCurrentUser = () => {
  const [getCurrentUser, { data, error }] = useMutation(signInMutation)
  const { id } = useSelector(state => state.camper)
  const { slug } = useSelector(state => state.campground)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!error && data && !id) {
      const { currentUser } = data.createUserSession

      setCookie('user_token', currentUser.authenticationToken, { expires: SIX_HOURS })
      dispatch(actionCamperSetData(currentUser))
      dispatch(detailsSetData(currentUser))
      dispatch(setIdentifyUser(currentUser))
    }
  }, [data])

  function signInUser(email, password) {
    getCurrentUser({ variables: { email, password, slug } })
  }

  const signInFacebook = (email, uid, name, picture) => {
    const provider = 'facebook'
    const password = 'nopass123'
    getCurrentUser({ variables: { email, password, omniauth: { uid, name, provider, picture }, slug } })
  }

  return [signInUser, signInFacebook, error]
}

export default useCurrentUser
