import { gql } from 'Utils/apolloClient'
import currentUserDataFragment from 'Checkout/Queries/CurrentUserDataFragment'

const signInMutation = gql`
  mutation CreateUserSession($email: String!, $password: String!, $filters: SiteFiltersAttributes,
                             $omniauth: OmniauthAttributes, $slug: String!) {
    createUserSession(input: { email: $email, password: $password, omniauth: $omniauth }) {
      currentUser {
        ...currentUserData
      }
    }
  }

  ${currentUserDataFragment}`

export default signInMutation
