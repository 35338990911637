export default (asyncFn, errorCallback) => (
  async (data, thunkAPI) => {
    try {
      return await asyncFn({ ...data })
    } catch (error) {
      if (errorCallback) return errorCallback({ error, data: { ...data, ...thunkAPI } })
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
