import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import ConfirmDialog from 'Shared/ConfirmDialog'

const BackConfirmDialog = ({ classButton, message, onConfirm }) => {
  const history = useHistory()

  const handleOnConfirm = () => {
    if (onConfirm) return onConfirm(history)

    if (['PUSH', 'REPLACE'].includes(history.action)) return history.goBack()

    history.push('/')
  }

  return (
    <ConfirmDialog message={message} onConfirm={handleOnConfirm} title="Back">
      <button className={classButton} type="button">
        <FontAwesomeIcon className="me-2" icon="chevron-left" />
        Back
      </button>
    </ConfirmDialog>
  )
}

BackConfirmDialog.defaultProps = {
  classButton: 'btn btn-white-salmon d-flex align-items-center me-2',
  message: 'Going back will reset the data you have input on this page. Would you like to continue?',
  onConfirm: null,
}

BackConfirmDialog.propTypes = {
  classButton: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
}

export default BackConfirmDialog
