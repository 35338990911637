import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import { useStateContext } from '../useStateContext'
import Anchor from 'Shared/Anchor/Anchor'

const Header = ({ children, showIcon, title }) => {
  const { handleState, isOpen } = useStateContext()
  const anchorID = `link-${title.toLowerCase().replace(/\s/g, '-').replace(/&/g, 'and')}`

  return (
    <div className="collapse-header d-flex align-items-center justify-content-between">
      <Anchor className="d-flex align-items-center btn p-0 text-blue" id={anchorID} onClick={handleState}>
        {showIcon && <FontAwesomeIcon className="text-lg" icon={isOpen ? 'chevron-up' : 'chevron-down'} />}

        <span className={`fw-bold ${showIcon && 'ps-2'}`}>{title}</span>
      </Anchor>

      <Collapse isOpen={!isOpen}>
        {children}
      </Collapse>
    </div>
  )
}

Header.defaultProps = {
  showIcon: true,
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
}

export default Header
