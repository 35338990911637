import { setSessionStorage } from 'Utils/SessionStorage'

const SearchReducer = (currentState = {}, action) => {
  const handler = searchHandlers[action.type]
  if (handler) return handler(currentState, action)

  return currentState
}

const searchFilterUpdate = (currentState, { payload = {} }) => ({
  ...currentState,
  ...payload,
})

const searchUpdateWithSessionStorage = (currentState, action) => {
  const { sessionKey, payload } = action

  setSessionStorage(sessionKey, payload)

  return {
    ...currentState,
    ...payload,
  }
}

const searchHandlers = {
  SEARCH_FILTER_UPDATE: searchFilterUpdate,
  SEARCH_UPDATE_WITH_SESSION_STORAGE: searchUpdateWithSessionStorage,
}

export default SearchReducer
