import PropTypes from 'prop-types'
import AlertErrors from 'Shared/Alert/AlertErrors'
import Rate from './Rate'
import Loading from 'Shared/Loading'

const CampsiteList = ({ campsites, checkOut, error, isFlexible, isMinNightsMuted, loading, onSelect,
                        showErrorMessage, siteError, sourceRequired }) => {
  if (loading) return <Loading message="Loading Campsites..." />

  if (error) return <AlertErrors graphQLErrors={error} />

  return (
    <div>
      {siteError && (
        <AlertErrors classNameList="list-unstyled" graphQLErrors={siteError} withScroll />
      )}

      {(campsites.length === 0 || !checkOut) && showErrorMessage ? (
        <div className="bg-light border p-5 rounded text-center">
          <div className="mb-3 mx-auto opacity-50 text-primary" style={{ width: '4em' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
          <h4>Select dates to see your availability.</h4>
        </div>
      ) : (
        campsites.map(rate => (
          <Rate {...{ isFlexible, isMinNightsMuted, rate, onSelect, sourceRequired }} key={`rate-${rate.id}`} />
        ))
      )}
    </div>
  )
}

CampsiteList.defaultProps = {
  campsites: [],
  checkOut: null,
  error: null,
  isMinNightsMuted: false,
  loading: true,
  showErrorMessage: true,
  siteError: null,
  sourceRequired: false,
}

CampsiteList.propTypes = {
  campsites: PropTypes.instanceOf(Array),
  checkOut: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  isMinNightsMuted: PropTypes.bool,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.bool,
  siteError: PropTypes.shape({}),
  sourceRequired: PropTypes.bool,
}

export default CampsiteList
