import { Children } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import BackConfirmDialog from './HeaderWithConfirm.BackConfirmDialog'

const HeaderWithConfirm = ({ children, className, isLoading, onClearItems, showBack, showClearButton, title,
                             to }) => {
  let _backConfirmDialog

  Children.forEach(children, child => {
    if (child?.type === BackConfirmDialog) {
      _backConfirmDialog = child
      return false
    }
  })

  return (
    <header className={className || 'py-2'}>
      <div className="d-flex align-items-center">
        {showBack && (
          _backConfirmDialog || (
            <Link className="btn btn-white-salmon d-flex align-items-center me-2" to={to}>
              <FontAwesomeIcon className="me-2" icon="chevron-left" />
              Back
            </Link>
          )
        )}

        <h2 className="fw-bold h3 m-0">{title}</h2>

        {showClearButton && (
          <Button className="fw-bold ms-auto py-0 shadow-sm" color="danger" disabled={isLoading}
                  onClick={onClearItems}>
            Clear
          </Button>
        )}
      </div>
    </header>
  )
}

HeaderWithConfirm.BackConfirmDialog = BackConfirmDialog

HeaderWithConfirm.defaultProps = {
  onClearItems: () => {},
  showBack: false,
  showClearButton: false,
  to: '/',
}

HeaderWithConfirm.propTypes = {
  onClearItems: PropTypes.func,
  showBack: PropTypes.bool,
  showClearButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
}

export default HeaderWithConfirm
