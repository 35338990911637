// Please, add any icon you need in the list below (keep it alphabetically sorted)
import { library } from '@fortawesome/fontawesome-svg-core'
import { FaBrandsIcons, FaIcons, FaRegularIcons } from 'Shared/FontAwesomeIcon/Icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  FaBrandsIcons.faCcAmex,
  FaBrandsIcons.faCcDinersClub,
  FaBrandsIcons.faCcDiscover,
  FaBrandsIcons.faCcMastercard,
  FaBrandsIcons.faCcVisa,
  FaBrandsIcons.faFacebookF,
  FaIcons.faBars,
  FaIcons.faBolt,
  FaIcons.faCaravan,
  FaIcons.faCaretDown,
  FaIcons.faChevronDown,
  FaIcons.faChevronRight,
  FaIcons.faChevronUp,
  FaIcons.faCheck,
  FaIcons.faCircleCheck,
  FaIcons.faCircleExclamation,
  FaIcons.faCircleInfo,
  FaIcons.faCreditCard,
  FaIcons.faDroplet,
  FaIcons.faEnvelope,
  FaIcons.faFilter,
  FaIcons.faInfoCircle,
  FaIcons.faLocationArrow,
  FaIcons.faLock,
  FaIcons.faMapMarker,
  FaIcons.faLocationDot,
  FaIcons.faMinus,
  FaIcons.faMugSaucer,
  FaIcons.faPaw,
  FaIcons.faPencil,
  FaIcons.faPenToSquare,
  FaIcons.faPhone,
  FaIcons.faPhoneAlt,
  FaIcons.faPhoneFlip,
  FaIcons.faPlug,
  FaIcons.faPlus,
  FaIcons.faSquareParking,
  FaIcons.faShieldHalved,
  FaIcons.faSliders,
  FaIcons.faSortDown,
  FaIcons.faSortUp,
  FaIcons.faSpinner,
  FaIcons.faShower,
  FaIcons.faSnowflake,
  FaIcons.faStar,
  FaIcons.faTag,
  FaIcons.faTriangleExclamation,
  FaIcons.faToilet,
  FaIcons.faTv,
  FaIcons.faUser,
  FaIcons.faUserGroup,
  FaIcons.faUtensils,
  FaIcons.faWifi,
  FaIcons.faXmark,
  FaRegularIcons.faCircleCheck,
  FaRegularIcons.faEnvelope,
  FaRegularIcons.faFaceSadCry,
  FaRegularIcons.faStar,
  FaRegularIcons.faStarHalfStroke,
)

export default FontAwesomeIcon
